import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { css } from '@emotion/core'

import SEO from 'src/components/SEO'
import TextSection from 'src/components/TextSection'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Modal from 'src/components/Modal'
import CateringForm from 'src/components/CateringForm'

const propTypes = {
	data: PropTypes.object.isRequired,
}

class TextPageTemplate extends React.Component {
	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.allContentfulTextPage.edges[0].node
		const restaurants = this.props.data.allContentfulRestaurant.edges
		const hasAtf = true

		return (
			<Fragment>
				<SEO
					title={page.title}
					siteSettings={site}
				/>
				<Header
					hasAtf={hasAtf}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          socialLinks={site.socialLinks}
          location={page.slug}
          title={site.title}
          restaurants={restaurants}
					site={site}
				/>

				<TextSection headline={page.title} theme='charcoal' alignment='center' headlineSize='h1' isFirstSection={true} />
				<TextSection text={page.text} setTheme='lightGrey' alignment='center' textAlignment='left' css={css`p { max-width: 100%; }`} />

				{page.footer !== 'hide footer' && (
					<Footer {...site} />
				)}
			</Fragment>
		)
	}
}

TextPageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulTextPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					text {
						raw
					}
				}
			}
    }
    allContentfulRestaurant(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}, sort: {order: ASC, fields: title}, ) {
	    edges {
	      node {
	        ...Restaurant
	      }
	    }
	  }
	}
`

export default TextPageTemplate
